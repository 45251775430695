<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="main">
        <div class="username"
             style="display:inline-block;float:right;color:#000000;margin-top:4px;">
          <span>当前角色：</span><span>{{ info.charac_name }}</span><a class="logout"
             href="javascript:;"
              @click="LoginDisplay = true">《登录|切换》</a>
        </div>
        <div style="color: rgb(255 23 23);margin-top:94px;font-size: 20px;font-weight: 700;">
          <div style="display: inline-block;width:25px;text-align: center;position: relative;left: 142px;">
            <span>{{ info.plug_config_info.current_num }}</span>
          </div>
          <div style="display: inline-block;width:25px;text-align: center;position: relative;left: 151px;">
            <span>{{ info.plug_config_info.room_num }}</span>
          </div>
        </div>
       
        <div>
           <el-row :gutter="20" style="height:322px;">
              <el-col :span="19">
                <ul class="monthsign">
                  <li class="lilis  selected" v-for="(item, index) in info.plug_config_info.qdao_data" :key="index">  
                    <el-tooltip class="item" effect="dark" content="" placement="right">
                        <div slot="content">
                            <div style="color: #babd9a;width: 113px;">
                                <p style="text-align:center;margin-bottom:6px;">{{ item.name }}</p>
                                <p style="margin-bottom:6px;" v-for="va, indx in item.code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                            </div>
                        </div>
                        <div>
                          <img  :src="item.ioc" style="width: 28px;position: relative;left: 13px;top: 27px;height: 28px;">
                          <div v-if="item.state == 1" class="zzhao"></div>
                          <div v-else-if="item.dq == 1" class="qdaoann" @click="qd"></div>
                        </div>    
                    </el-tooltip> 
                  </li>
                </ul>
              </el-col>
               <el-col :span="5">
                 <div class="list-container">
                    <ul>
                        <li v-for="(item, index) in pagin.monthsign_lj" :key="index">
                          <el-tooltip class="item" effect="dark" content="" placement="left">
                              <div slot="content">
                                  <div style="color: #babd9a;width: 200px;">
                                      <p style="text-align:center;margin-bottom:6px;">{{ item.name }}</p>
                                      <p style="margin-bottom:6px;" v-for="va, indx in item.code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                                  </div>
                              </div>
                              <div>
                                <img :src="item.ioc" style="width: 30px;position: relative;left: 20px;top: 0px;height: 30px;">
                                <div v-if="item.state == 1" class="lj_zzhao"></div>
                                <div v-else-if="item.dq == 1" class="lj_qdaoann" @click="ljqd(item.id)"></div>
                                <div v-if="item.state == 1" style="text-align:center;font-weight: 700;color: rgb(151, 117, 121);position: relative;top: -27px;">
                                    <span>累计{{item.day}}天</span>
                                </div>
                                <div  v-else-if="item.dq == 1" style="text-align:center;font-weight: 700;color: rgb(151, 117, 121);position: relative;top: -27px;">
                                    <span>累计{{item.day}}天</span>
                                </div>
                                <div  v-else style="text-align:center;font-weight: 700;color: rgb(151, 117, 121);position: relative;top: 10px;">
                                    <span>累计{{item.day}}天</span>
                                </div>
                              </div>    
                          </el-tooltip> 


                           
                        </li>
                        <!-- Add more list items here -->
                    </ul>
                </div>
                <div class="previous"  @click="prevPage()"></div>
                <div class="next" @click="nextPage()"></div>
               </el-col>
           </el-row> 
        </div>
        <div style="text-align: center;margin-top: 2px;color: #7e705a;">
            <span>活动期间通关<span style="color:rgb(27 80 199);">[{{ info.plug_config_info.room_name }}]</span>副本即可完成签到奖励</span>
        </div>
      </div>
      <div class="iteminfo">
        <!-- <div style="text-align:center;">物品礼包</div>
        <div v-for="(itmec, i) in itme_show_data"
             :key="i"
             style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
        </div> -->
      </div>
      <div class="titlehover">……</div>
      <div class="alert">
        <!-- 弹出框 -->
        <div class="alert_bg" v-if="MessageDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="CloseAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 弹出框end -->
        <!-- 登陆框 -->
        <div class="alert_bg"
             v-if="LoginDisplay">
          <div class="alert_main"
               style="margin-top:86px;width:350px;">
            <div class="alert_title"><span>绑定|切换</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏账号：</span><input type="text"
                         v-model="login_info.username"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏密码：</span><input type="password"
                         v-model="login_info.password"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">绑定角色：</span>
                  <select v-model="login_info.role"
                          style="width:154px;">
                    <option v-for="(itmec, i) in role_data"
                            :key="i"
                            :value="itmec.charac_no">
                      {{ itmec.charac_name }}</option>
                  </select>
                  <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                        @click="getRole">获取</span>
                </p>
                <div>
                  <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{ LoginError }}</span></span>
                </div>
                <div style="text-align:center;padding:10px 0px">
                  <div class="btn"
                       @click="login"
                       style="margin:0px 0px;display:inline-block;">绑定</div>
                  <div class="btn"
                       @click="LoginDisplay = false"
                       style="margin:0px 0px;display:inline-block;">取消</div>
                </div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  <script>
  import Vue from 'vue'
  export default {
    name: "Monthsign",
    data () {
      return {
        MessageDisplay: false, //弹出框显示
        LoginDisplay: false, //登陆弹出框
        LoginError: '',
        MessageMsg: '', //弹出框消息
        Loading: false,
        info: {
          m_id: 0, //账号uid
          charac_no: 0, //角色ID
          charac_name: '', //角色名称
          plug_config_info:{
            monthsign_lj:[], //累计数据
            qdao_data:[], //签到数据
            room_name:'',
            room_num:0,
            current_num:0,
          }, 
        },
        login_info: {
          username: '',
          password: '',
          role: '',
        },
        role_data: [],
        pagin:{
          monthsign_lj:[],
          currentPage: 1,      // 当前页码，默认为第一页
          itemsPerPage: 3,    // 每页显示的数据条数
        },
       
      };
    },
    created () {
      this.getInfo()
    },
    methods: {
      //获取基本配置信息
      getInfo () {
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/monthsign/getMonthsignInfo',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.info = response.data.info;
            this.paginatedItems();
          } else {
            this.PopMessage(true, response.data.msg)
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //登陆
      login () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/PlugLogin',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.LoginDisplay = false
            this.getInfo()
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/getRole',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.role_data = response.data.info
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      
      //弹出框状态
      PopMessage (type, msg) {
        this.MessageDisplay = false
        this.MessageMsg = msg
        if (type == true) {
          this.MessageDisplay =true
        } else {
          this.getInfo()
        }
      },
      //关闭窗口
      CloseAlert () {
        this.PopMessage(false, '')
      }, 
      prevPage() {
        if (this.pagin.currentPage > 1) {
          this.pagin.currentPage--;
           this.paginatedItems();
        }
      },
      nextPage(){
        if (this.pagin.currentPage < this.info.plug_config_info.monthsign_lj.length) {
          this.pagin.currentPage++;
          this.paginatedItems();
         
        }
      },
      paginatedItems() {
        var startIndex = (this.pagin.currentPage - 1) * this.pagin.itemsPerPage;
        var endIndex = startIndex + this.pagin.itemsPerPage;
        var monthsign_lj = [];
        this.info.plug_config_info.monthsign_lj.forEach(function (item, index) {
          if(index >= startIndex && index < endIndex){
            monthsign_lj.push(item);
          }  
        });
        if(monthsign_lj.length > 0){
          this.pagin.monthsign_lj = monthsign_lj;
        }
       
      },
      //签到按钮
      qd(){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/monthsign/MonthsignQd',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
       //签到按钮
       ljqd(ids){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/monthsign/MonthsignLjQd',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            id:ids,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
    },
  };
  </script>
  
  
  <style scoped>
  @import "../../../public/static/plug/monthsign/static/css/game.css";
  </style>
  

  

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
<style lang="scss" scoped>
 /deep/ .el-row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
</style>
  
  